.envio-page {
  text-align: center;
  padding: 20px;
}

.envio-page h1 {
  margin-bottom: 20px;
  font-size: 24px; /* Diminui o tamanho do título */
}

.photo {
  width: 90%;
  height: auto;
  max-width: 700px;
  margin: 20px 0;
  object-fit: cover;
}

.controls {
  display: inline-flexbox; 
  justify-content: space-around; /* Centraliza os botões horizontalmente */
  gap: 10px; /* Adiciona um espaço entre os botões */
  flex-wrap: wrap; /* Permite que os botões quebrem linha em telas menores */
}

.controls button {
  padding: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px;
}

.send-button {
  background-color: #007bff;
  color: white;
  border: none;
}

.retake-button {
  background-color: #dc3545;
  color: white;
  border: none;
}

.send-button:hover,
.retake-button:hover {
  opacity: 0.8;
}

/* Media queries para responsividade */
@media (max-width: 1000px) {
  .envio-page h1 {
    font-size: 18px; /* Ajusta o tamanho do título para dispositivos móveis */
  }

  .photo {
    width: 100%;
    max-width: 700px;
  }

  .controls {
    flex-direction: column; /* Alinha os botões verticalmente em telas menores */
    gap: 10px; /* Diminui o espaço entre os botões em telas menores */
  }

  .controls button {
    padding: 10px 10px;
    font-size: 16px;
  }
}

/* Adicione estes estilos ao arquivo existente */
.etapa-selection {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.etapa-selection label {
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.etapa-selection input {
  margin-right: 10px;
  width: 20px;
  height: 20px; /* Ajuste para tornar os checkboxes quadrados e mais visíveis */
}
 