/* LoginPage.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f9fb;
}

.login-container {
  display: flex;
  height: 100vh;
}

.login-left {
  flex: 1;
  background-color: #0d2b5e;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.login-left h1 {
  font-size: 48px;
  margin: 0;
}

.login-left p {
  font-size: 24px;
  margin: 20px 0 0;
}

.login-right {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-right h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.login-right input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-right button {
  width: 55%;
  padding: 10px;
  background-color: #0070e0;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.login-right button:hover {
  background-color: #005bb5;
}

.login-right p {
  margin-top: 20px;
  text-align: center;
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .login-container {
      flex-direction: column;
      height: auto;
  }

  .login-left, .login-right {
      flex: none;
      width: 100%;
      padding: 20px;
      box-shadow: none;
  }

  .login-left h1 {
      font-size: 32px;
  }

  .login-left p {
      font-size: 18px;
  }

  .login-right h2 {
      font-size: 24px;
  }

  .login-right input, .login-right button {
      width: 90%;
  }
}
