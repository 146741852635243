body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.aulas-page {
  padding: 20px;
  background-color: #e0e0e0; /* Cor de fundo mais escura */
}

h1 {
  text-align: center;
  color: #333;
  font-size: 32px;
}

.aulas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.aula-item {
  display: flex;
  justify-content: center;
}

.aula-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra mais proeminente */
  padding: 20px;
  max-width: 500px;
  width: 100%;
  transition: transform 0.3s;
}

.aula-card:hover {
  transform: scale(1.05);
}

.aula-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #007bff;
}

.aula-card p {
  margin: 5px 0;
  color: #555;
}

.label {
  font-weight: bold;
  color: #333;
}

.time-row {
  display: flex;
  justify-content: space-between;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: medium;
}

.button-container .MuiButton-containedPrimary {
  background-color: #007bff;
  color: #fff;
  padding: 12px 24px;
  font-size: 1rem;
}

.button-container .MuiButton-containedPrimary:hover {
  background-color: #0056b3;
}

@media (max-width: 1000px) {
  h1 {
    text-align: center;
    color: #333;
    font-size: 46px;
  }
  .aula-card {
    max-width: 95%;
  }

  .time-row {
    flex-direction: row;
    gap: 10px;
  }

  .aula-card h2 {
    font-size: 2.5em;
  }

  .aula-card p {
    font-size: 2.1em;
  }

  .label {
    font-size: 1.2em;
  }

  .button-container .MuiButton-containedPrimary {
    padding: 14px 28px;
    font-size: 2.1rem;
  }
}

@media (max-width: 480px) {
  .aula-card {
    max-width: 98%;
    padding: 15px;
  }

  .time-row {
    flex-direction: column;
    gap: 10px;
  }

  .aula-card h2 {
    font-size: 1.1em;
  }

  .aula-card p {
    font-size: 0.8em;
  }

  .label {
    font-size: 0.9em;
  }

  .button-container .MuiButton-containedPrimary {
    padding: 16px 32px;
    font-size: 1.2rem;
  }
}
