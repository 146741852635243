.mask {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28%; /* 40% width for desktops */
  height: auto;
  aspect-ratio: 2 / 3;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0 0 2000px rgba(255, 255, 255, 0.95);
  transform: translate(-50%, -50%);
  border: 15px solid transparent; /* Define a borda inicial como transparente */
  transition: border-color 0.3s; /* Adiciona uma transição suave para a mudança de cor */
}

@media only screen and (max-width: 1000px) {
  .mask {
    width: 70%; /* 100% width for mobile devices */
  }
}

.capture-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente */
  height: 100vh;
  text-align: center;
}

.webcam-container {
  position: relative;
  width: 100%; /* ou uma largura específica se você não quer que ocupe toda a largura */
  height: 100%; /* ou uma altura específica se você não quer que ocupe toda a altura */
  display: flex;
  justify-content: center; /* Centraliza horizontalmente dentro do container */
  align-items: center; /* Centraliza verticalmente dentro do container */
}

.webcam {
  width: 60%;
  height: 50%;
  object-fit: cover;
  transform: scaleX(-1); /* Espelha o vídeo horizontalmente */
  z-index: 1; /* Garante que a webcam está abaixo da máscara */
}

.captured-image {
  width: 100%;
  height: 100vh;
  object-fit: contain; /* Mantém as proporções reais da imagem */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3; /* Garante que a imagem capturada está acima da máscara */
}
.capture-button,
.send-button,
.retake-button {
  width: 50%; /* Ajuste do tamanho dos botões */
  padding: 25px 200px;
  margin: 15px;
  font-size: 54px; /* Ajuste do tamanho da fonte dos botões */
  border: none;
  border-radius: 45px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  z-index: 4; /* Garante que os botões estão acima da máscara */
  position: relative;
  transition: background-color 0.3s;
}

.capture-button:hover,
.send-button:hover,
.retake-button:hover {
  background-color: #09b300;
}

.instructions {
  position: absolute;
  top: 2%; /* Posição do texto pouco acima da máscara */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  color: yellow;
  text-shadow: 2px 2px 3px black;
  z-index: 3;
}

.instructions.green {
  color: green;
}

.instructions.red {
  color: red;
}

.bottom-button-container {
  position: absolute;
  top: 89%; /* Posição do botão pouco abaixo da máscara */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0 20px; /* Adiciona espaçamento lateral */
  z-index: 4; /* Garante que os botões estão acima da máscara */
}

@media only screen and (max-width: 1000px) {
  .webcam {
    width: 80%;
    height: 50%;
    object-fit: cover;
    transform: scaleX(-1); /* Espelha o vídeo horizontalmente */
    z-index: 1; /* Garante que a webcam está abaixo da máscara */
  }
  .instructions {
    top: 7%; /* Ajuste da posição do texto para dispositivos móveis */
    font-size: 35px; /* Tamanho do texto ajustado para dispositivos móveis */
    z-index: 4; /* Garante que os botões estão acima da máscara */
  }

  .bottom-button-container {
    top: 85%; /* Ajuste da posição dos botões para dispositivos móveis */
    justify-content: space-around;
  }

  .capture-button,
  .send-button,
  .retake-button {
    font-size: 46px; /* Ajuste do tamanho da fonte dos botões para dispositivos móveis */
  }

  .captured-image {
    width: 100%;
    height: auto; /* Ajuste a altura automaticamente */
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button-container {
    bottom: 20%;
  }
}
